class Ecommerce {
    constructor() {
        this.initLayer();
        this.initEvents();
        this.initPage();
    }

    saveToLocalStorage(key, value) {
        try {
            window.localStorage.setItem(key, value)
        } catch (e) {
            console.log('Local storage unavailable');
        }
    }

    getFromLocalStorage(key, defaultValue) {
        try {
            const item = window.localStorage.getItem(key);
            if (item) {
                return item;
            }
        } catch (e) {
            console.log('Local storage unavailable');
        }
        return defaultValue;
    }

    initLayer() {
        window.dataLayer = window.dataLayer || [];
    }

    initEvents() {
        document.addEventListener('cartAdd', (e) => {
            const eventObject = this.elementToProductFieldObject(e.detail.element);
            if (!eventObject) {
                return;
            }
            if (e.detail.quantity) {
                eventObject.quantity = e.detail.quantity;
            }

            this.pushAdd(eventObject);
        });
    }

    initPage() {
        const productPageElement = document.querySelector('[data-ecommerce-product-page]');
        if (productPageElement) {
            const eventObject = this.elementToProductFieldObject(productPageElement);
            if (eventObject) {
                this.pushDetail(eventObject);
            }
        }


        const purchasePageElement = document.querySelector('[data-ecommerce-purchase]');
        if (purchasePageElement) {
            const id = purchasePageElement.dataset.ecommercePurchaseId;
            const encodedProductObjects = purchasePageElement.dataset.ecommercePurchase;
            const productObjects = JSON.parse(encodedProductObjects);

            const purchaseKey = `ECOMMERCE_PURCHASE_${id}`;
            const alreadySaved = this.getFromLocalStorage(purchaseKey);
            if (!alreadySaved) {
                this.pushPurchase(productObjects, id);
                this.saveToLocalStorage(purchaseKey, 'SAVED');
            }
        }
        // Purchase page
    }

    pushAdd(productObject) {
        window.dataLayer.push({
            ecommerce: {
                currencyCode: "RUB",
                add: {
                    products : [
                        productObject
                    ]
                }
            }
        });
    }

    pushDetail(productObject) {
        window.dataLayer.push({
            ecommerce: {
                currencyCode: "RUB",
                detail: {
                    products : [
                        productObject
                    ]
                }
            }
        });
    }

    pushPurchase(productObjects, id) {
        window.dataLayer.push({
            ecommerce: {
                currencyCode: "RUB",
                purchase: {
                    actionField : {
                        id: id
                    },
                    products : [
                        ...productObjects
                    ]
                }
            }
        });
    }

    elementToProductFieldObject(element) {
        const ecommerceEncodedData = element.dataset.ecommerce;
        if (!ecommerceEncodedData) {
            return;
        }
        return JSON.parse(ecommerceEncodedData);
    }
}

const ecommerce = new Ecommerce();