<template>
  <div class="cart-result">
    <div class="cart-result__content">
      <ul class="cart-result__info">
        <li class="cart-result__info-item">
          <div class="cart-result__info-name text text__small">
            Итого {{ totalCount }} товаров(а) на сумму
          </div>
          <h4 class="cart-result__info-value">
            {{ formatPrice(itemsPrice) }} ₽
          </h4>
        </li>
        <li class="cart-result__info-item">
          <div class="cart-result__info-name text text__small">
            Стоимость доставки
          </div>
          <div class="cart-result__info-value text__body">
            Расчитывается менеджером
          </div>
        </li>
      </ul>
    </div>

    <div class="cart-result__help">
      <div class="cart-result__help-text">
        <div class="cart-result__help-title text__main">
          Нужна помощь с заказом
        </div>
        <div class="cart-result__help-subtitle text text__small">
          Перезвоним через 5 минут
        </div>
      </div>
      <a href="/request/recall-modal"
         class="cart-result__help-button button button_border"
         data-modal
         data-goal-yandex-click="click_form_a_call"
         data-goal-yandex-submit="success_order_form_a_call"
      >
          <span class="button__text text__button">
              Заказать звонок
          </span>
      </a>
    </div>
  </div>
</template>

<script>
import QuestionIcon from "../icons/QuestionIcon.vue";
import {mapActions, mapGetters, mapState} from "vuex";

// import Popup from '../common/Popup.vue';
// import modalMixin from '../../mixins/modal';
import TheCartOrderFast from "./TheCartOrderFast.vue";

export default {
  name: "TheCartResult",
  components: {
    TheCartOrderFast,
    QuestionIcon,
    // Popup
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout',
    }),
    ...mapGetters('cart', {
      'totalCount': 'totalCount',
      'total': 'total',
      'deliveryPrice': 'deliveryPrice',
      'itemsPrice': 'itemsPrice',
    }),
    ...mapGetters('order', {
      'isDelivery': 'isDelivery',
    }),
    huiPrice() {
      let total = this.itemsPrice;
      if (this.isDelivery) {
        total += this.deliveryPrice
      }

      return total;
    }
  },
  methods: {
    formatPrice: function(rawValue) {
      const value = parseInt(rawValue, 10);
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    // openFastOrder() {
    //   this.openModal('fast_order');
    // }
  },
  // mixins: [
  //   modalMixin,
  // ],
}
</script>