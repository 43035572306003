export default class Office {
  constructor(listItemElement) {
    this.settings = Object.assign({
      lat: 0,
      lng: 0,
      type: '',
      zoom: undefined,
      opened: undefined
    }, listItemElement.dataset);

    this.placemark = undefined;

    this.listItemElement = listItemElement;

    this.link = this.listItemElement.querySelector('[data-list-item-link]');

    this.routeLink = this.listItemElement.querySelector('[data-list-item-route-link]');
  }

  getLat() {
    return parseFloat(this.settings.lat);
  }

  getLng() {
    return parseFloat(this.settings.lng);
  }

  show() {
    this.listItemElement.classList.add('_opened');
  }

  hide() {
    this.listItemElement.classList.remove('_opened');
  }

  setPlacemark(placemark) {
    this.placemark = placemark;
  }

  getPlacemark() {
    return this.placemark;
  }
}