<template>
  <div class="cart-positions">
    <ul class="cart-positions__list">
      <li class="cart-positions__item" v-for="item in checkout.items">
        <div class="cart-positions__image-wrap">
          <img :src="item.imagePath"
               :alt="item.buyable.fullName"
               class="cart-positions__image"
               v-if="item.imagePath"
          >
          <img src="/static/images/base/noimage.png"
               alt="no-image"
               class="cart-positions__image"
               v-else
          >
        </div>

        <div class="cart-positions__name-wrap">
          <div class="cart-positions__name">
            {{ item.buyable.fullName }}
          </div>
          <div class="cart-positions__item-price">
            <div class="cart-positions__price cart-positions__price_item text__main">
              {{ formatPrice(item.item_price) }} ₽
            </div>

            <div class="cart-positions__measure text text__mini">
              /шт
            </div>
          </div>
        </div>

        <div class="cart-positions__panel-wrap">
          <div class="cart-positions__panel">
            <button class="cart-positions__panel-button" @click="decrease({key: item.key})">
              <MinusIcon/>
            </button>
            <input type="text" class="cart-positions__panel-input"
                 v-model.number="item.quantity"
            />
            <button class="cart-positions__panel-button" @click="increase({key: item.key})">
              <PlusIcon/>
            </button>

            <div class="cart-positions__panel-message text" v-if="panelMessages[item.key]">
              {{ panelMessages[item.key] }}
            </div>
          </div>
        </div>

        <div class="cart-positions__info-wrap">
          <div class="cart-positions__total-quantity text text__mini">
            Доступно {{ item.buyable.quantity }} шт.
          </div>
        </div>

        <div class="cart-positions__price-wrap">
          <div class="cart-positions__price cart-positions__price_total text__subtitle">
            {{ formatPrice(item.price)}} ₽
          </div>
        </div>

        <div class="cart-positions__buttons-wrap">
          <a href="javascript:void(0);"
             class="cart-positions__button-icon"
             @click="remove({key: item.key})"
             data-goal-yandex-click="click_delete_tovar"
          >
            <DeleteIcon/>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import CloseIcon from "../icons/CloseIcon.vue";
import FavoritesIcon from "../icons/FavoritesIcon.vue";
import MinusIcon from "../icons/MinusIcon.vue";
import PlusIcon from "../icons/PlusIcon.vue";
import DeleteIcon from "../icons/DeleteIcon.vue";

export default {
  name: "TheCartPositions",
  components: {
    DeleteIcon,
    CloseIcon,
    FavoritesIcon,
    MinusIcon,
    PlusIcon
  },
  methods: {
    ...mapActions('cart', {
      'remove': 'remove',
      'increase': 'increase',
      'decrease': 'decrease',
    }),
    formatPrice: function(rawValue) {
      const value = parseInt(rawValue, 10);
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout',
      'panelMessages': 'panelMessages'
    }),
  },

}
</script>
