<template>
  <a href="/cart"
     title="Перейти в корзину"
     data-goal-yandex-click="click_go_cart"
     class="header__cart-wrap"
  >
    <div class="header__cart">
          <span class="header__cart-icon">
              <CartIcon/>
              <span class="header__cart-count">
                 {{ count }}
              </span>
          </span>
    </div>
    <div class="header__cart-text">
      Корзина
    </div>
  </a>
</template>

<script>
import CartIcon from "../icons/CartIcon";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "TheCartWidgetMobile",
  components: {
    CartIcon
  },
  methods: {
    ...mapActions({
      'loadCheckout': 'cart/loadCheckout'
    })
  },
  computed: {
    ...mapGetters('cart', {
      'total': 'total',
      'count': 'count'
    })
  },
  mounted() {
    this.loadCheckout();
  }
}
</script>