import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Pagination, Parallax, Swiper} from 'swiper';

function init() {
    const container = document.querySelector('[data-hero-slider-container]');
    if (!container) {
        return;
    }

    const element = container.querySelector('[data-hero-slider]');
    if (!element) {
        return;
    }

    const prevElement = container.querySelector('[data-hero-slider-prev]');
    const nextElement = container.querySelector('[data-hero-slider-next]');
    const paginationElement = container.querySelector('[data-hero-slider-pagination]');

    const slider = new Swiper(element, {
        modules: [Navigation, Parallax, Pagination],
        speed: 1500,
        parallax: true,
        preventInteractionOnTransition: true,
        loop: true,
        navigation: {
            prevEl: prevElement,
            nextEl: nextElement,
        },
        pagination: {
            el: paginationElement,
            type: 'bullets',
            clickable: true,
        },
    });
}

onDomReady(init);