<template>
  <div class="cart-order-block cart-order-block_pay">
    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number text__main">
          2
        </div>
      </div>
      <h3 class="cart-order-block__title text__subtitle">
        Способ оплаты
      </h3>
    </div>

    <div class="cart-order-block__content form-field" v-if="isPhysical">
      <ItemErrors :errors="errors.payment"/>
      <ul class="cart-order-block__radio-list">
        <li class="cart-order-block__radio-item" v-if="this.hasNotInStockPositions() === false">
          <div class="cart-order-block__radio">
            <input type="radio" name="payment" v-model.number="payment" id="order_cash" value="10">
            <label for="order_cash" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title text__main">
                Наличные средства или карта при получении
              </span>
              <span class="cart-order-block__label-description text text__small">
                Оплата производится наличными при получении товаров или услуг
              </span>
            </label>
          </div>
        </li>
        <li class="cart-order-block__radio-item">
          <div class="cart-order-block__radio">
            <input type="radio" name="payment" v-model.number="payment" id="order_online" value="20">
            <label for="order_online" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title  text text__main text__main_medium">
                Банковской картой
              </span>
              <span class="cart-order-block__label-description text text__small">
                Visa, Mastercard, Мир, SberPay
              </span>
            </label>
          </div>
        </li>
        <li class="cart-order-block__radio-item">
          <div class="cart-order-block__radio">
            <input type="radio" name="payment" v-model.number="payment" id="order_credit" value="30">
            <label for="order_credit" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title  text text__main text__main_medium">
                Оформление кредита
              </span>
              <span class="cart-order-block__label-description text text__small">
                Оформление кредита в одном из банков-партнеров
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>
    <div class="cart-order-block__content form-field" v-if="isLegal">
      <ItemErrors :errors="errors.payment"/>
      <ul class="cart-order-block__radio-list">
        <li class="cart-order-block__radio-item">
          <div class="cart-order-block__radio">
            <input type="radio" name="payment" v-model.number="payment" id="order_transfer" value="40">
            <label for="order_transfer" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title text__main">
                Безналичный расчет
              </span>
              <span class="cart-order-block__label-description text text__small">
                Оплата по безналичному расчету применяется только в том случае, если заказ оформляется на юридическое лицо
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import ItemErrors from "./ItemErrors.vue";

export default {
  name: "TheCartOrderPay",
  data() {
    return {
      checked: false
    }
  },
  components: {
    ItemErrors
  },
  computed: {
    ...mapState('order', {
      'payment': 'payment'
    }),
    ...mapState('cart', {
      'checkout': 'checkout',
    }),

    payment: {
      get () {
        return this.$store.state.order.payment
      },
      set (value) {
        this.$store.commit('order/setPayment', parseInt(value, 10))
      }
    },
    errors: {
      get() {
        return this.$store.state.order.errors
      }
    },
    ...mapGetters('order', {
      'isPhysical': 'isPhysical',
      'isLegal': 'isLegal',
    }),
  },
  methods: {
    hasNotInStockPositions() {
      const positions = this.checkout.items
      let result = false;
      positions.forEach((position) => {
        if (position.buyable.deliveryString !== 'В наличии') {
          result = true;
          if (!this.checked) {
            this.payment = 20;
          }
        }
      })

      this.checked = true;
      this.checkPhysical();
      return result;
    },
    checkPhysical() {
      document.addEventListener('setPhysical', () => {
        this.checked = false;
        this.hasNotInStockPositions();
      });
    }
  }
}
</script>

<style lang="scss">

</style>
