import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Pagination, Parallax, Swiper} from 'swiper';
import RSlider from "../../components/rslider/rslider";

function init() {
    const containerElement = document.querySelector('[data-advantages-slider-container]');
    if (!containerElement) {
        return;
    }

    const sliderElement = document.querySelector('[data-advantages-slider]');
    if (!sliderElement) {
        return;
    }

    const prevElement = containerElement.querySelector('[data-advantages-slider-prev]');
    const nextElement = containerElement.querySelector('[data-advantages-slider-next]');
    const paginationElement = containerElement.querySelector('[data-advantages-slider-pagination]');

    const slider = new Swiper(sliderElement, {
        modules: [Navigation, Parallax, Pagination],
        speed: 1000,
        parallax: true,
        preventInteractionOnTransition: true,
        navigation: {
            prevEl: prevElement,
            nextEl: nextElement,
        },
        pagination: {
            el: paginationElement,
            type: 'fraction',

            renderFraction: function (currentClass, totalClass) {
                return '<span class="slider-fraction-pagination__current ' + currentClass + '"></span>' +
                    ' <span class="slider-fraction-pagination__delimiter">/</span> ' +
                    '<span class="slider-fraction-pagination__total ' + totalClass + '"></span>';
            }
        },
    });

    const textElement = document.querySelector('[data-rslider="advantages-text-slider"]');
    if (!textElement) {
        console.log('Could not find any element with advantages-text-slider');
        return;
    }
    const textSlider = new RSlider(
        textElement,
        {
            onStart: ({ slideIn, slideFriendsIn }) => {
                slideFriendsIn.forEach((friendIn) => {
                    friendIn.classList.add('_active');
                })
                slideIn.classList.add('_active');
            },
            timeline: [
                RSlider.action({
                    // К слайдам докидывается:
                    // К появляющемуся: --rslider-first-act-in-progress: 0.2;
                    // К исчезающему: --rslider-first-act-out-progress: 0.2;
                    // easing ниже настраивается
                    name: 'next-act',
                    duration: 1000,
                    delay: 0,
                    // easeInOutCubic прогресс для "приходящего" слайда
                    // easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
                    // linear прогресс для "уходящего" слайда
                    // easingOut: (x) => x,
                    onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideIn.classList.add('_active');
                        slideOut.classList.remove('_active');

                        slideIn.classList.add('_in-next-act');
                        slideOut.classList.add('_out-next-act');

                        slideFriendsIn.forEach((friendIn) => {
                            friendIn.classList.add('_active');
                            friendIn.classList.add('_in-next-act');
                        })

                        setTimeout(() => {
                            slideIn.classList.remove('_in-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.remove('_in-next-act');
                            })
                        }, 500);

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_active');
                            friendOut.classList.add('_out-next-act');
                        })
                    },
                    onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideOut.classList.remove('_out-next-act');

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_out-next-act');
                        })
                    }
                }),
            ],
        });

    slider.on('slideNextTransitionStart', () => {
        textSlider.next();
    });

    slider.on('slidePrevTransitionStart', () => {
        textSlider.prev();
    });
}

onDomReady(init);