<template>
  <div class="chosen-fieldset" :class="blockClasses" ref="root">
    <div class="chosen-fieldset__fields"
         :class="elementClasses('fields')"
    >
      <div class="chosen-fieldset__field"
           :class="[...elementClasses('field'), ...elementClasses('field_' + field.key)]"
           v-for="field in fields"
      >
        <Field v-if="field.visible" :fieldKey="field.key" :filterKey="filterKey" @change="debouncedChange"/>
      </div>

      <a href="javascript:void(0)" class="chosen-fieldset__clear-all button button__mini"
        v-if="this.hasVisibleFields() === true"
         @click="this.clear()"
      >
        <span class="button__text text__small">
            Очистить все
        </span>
      </a>
    </div>
  </div>
</template>

<script>
  import {fieldsetMixin} from "./fieldsetMixin";
  import Field from "../field/Field.vue";
  import { FilterManagerPool } from "../manager";
  import { debounce } from "lodash";

  export default {
    name: "ChosenFieldset",
    components: {Field},
    mixins: [
      fieldsetMixin
    ],
    computed: {
      currentUrl() {
        return window.location.href;
      },
    },
    methods: {
      change() {
        if (this.fieldset.data.instantСhangeable) {
          this.manager.submit();
        }
      },
      hasVisibleFields() {
        let isVisible = false;
        if (this.fields) {
          this.fields.forEach((element) => {
            if (element.visible === true) {
              isVisible = true;
            }
          })
        }

        return isVisible;
      },
      debouncedChange: debounce(function() {
        this.change();
      }, 300),
      clear() {
        this.manager.submit(true);
      }
    },
    mounted() {
      this.manager = FilterManagerPool.getManager(this.filterKey);
    },
  }
</script>

<style lang="scss">

</style>