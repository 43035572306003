import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from 'swiper';

function init() {
    const sliderContainer = document.querySelector('[data-produce-slider-container]');
    if (!sliderContainer) {
        return;
    }

    const swipeSliderElement = sliderContainer.querySelector('[data-produce-slider]');
    if (!swipeSliderElement) {
        return;
    }

    const leftArrow = sliderContainer.querySelector('[data-produce-slider-prev]');
    const rightArrow = sliderContainer.querySelector('[data-produce-slider-next]');

    const slider = new Swiper(swipeSliderElement, {
        modules: [Navigation],
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        breakpoints: {
            0: {
                slidesPerView: "auto",
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 18,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 28,
            },
        }
    });
}

onDomReady(init);