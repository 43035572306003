<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7786 2.12753L4.72007 9.64465L0.335667 5.7474L1.66439 4.25258L4.48002 6.75537L9.22145 0.872481L10.7786 2.12753Z" fill="#D7262D"/>
  </svg>
</template>

<script>
  export default {
    name: "CheckMarkBlack"
  }
</script>

<style scoped>

</style>