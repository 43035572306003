import { createPinia } from "pinia";
import { initFilters } from "./filter/init";
import mitt from 'mitt';
import maska from "maska";

import { createApp } from 'vue';
import App from './App.vue';

import store from './store';
import TheCartWidget from "./components/cart/TheCartWidget.vue";
import TheCartWidgetMobile from "./components/cart/TheCartWidgetMobile.vue";
import TheCartPage from "./components/cart/TheCartPage.vue";
import TheNotification from "./components/notification/TheNotification.vue";
import TheFavoritesWidget from "./components/favorites/TheFavoritesWidget.vue";
import TheFavoritesWidgetMobile from "./components/favorites/TheFavoritesWidgetMobile.vue";

const pinia = createPinia();
initFilters(pinia);

const emitter = mitt();

if (document.querySelector('#app')) {
    const app = createApp(App);
    app.mount('#app');
}

if (document.querySelectorAll('#cart-widget')) {
    const app = createApp(TheCartWidget).use(store);
    app.config.globalProperties.emitter = emitter;
    app.mount('#cart-widget');
}

if (document.querySelectorAll('#cart-widget-mobile')) {
    const app = createApp(TheCartWidgetMobile).use(store);
    app.config.globalProperties.emitter = emitter;
    app.mount('#cart-widget-mobile');
}

if (document.querySelector('#cart-page')) {
    const app = createApp(TheCartPage).use(store);
    app.use(maska);
    app.config.globalProperties.emitter = emitter;
    app.mount('#cart-page');
}

if (document.querySelector('#notifications')) {
    const app = createApp(TheNotification).use(store);
    app.config.globalProperties.emitter = emitter;
    app.mount('#notifications');
}

if (document.querySelector('#favorites-widget')) {
    const app = createApp(TheFavoritesWidget).use(store);
    app.config.globalProperties.emitter = emitter;
    app.mount('#favorites-widget');
}

if (document.querySelector('#favorites-widget-mobile')) {
    const app = createApp(TheFavoritesWidgetMobile).use(store);
    app.config.globalProperties.emitter = emitter;
    app.mount('#favorites-widget-mobile');
}

document.addEventListener('cartAdd', (e) => {
    store.dispatch('cart/add', e.detail);
});

document.addEventListener('favoritesAdd', (e) => {
    store.dispatch('favorites/add', e.detail);
});

document.addEventListener( 'favoritesRemove', (e) => {
    store.dispatch('favorites/remove', e.detail);
});