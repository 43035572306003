import { createStore } from 'vuex'

import cart from './modules/cart.module';
import order from './modules/order.module';
import pickupPoints from './modules/pickupPoints.module';
import notification from './modules/notification.module';
import favorites from "./modules/favorites.module";

// Create a new store instance.
const store = createStore({
  modules: {
    cart,
    order,
    pickupPoints,
    notification,
    favorites
  },
  getters: {
    isLoading(state) {
      let loading = false;
      for (const key in state) {
        if (state.hasOwnProperty(key) && state[key].hasOwnProperty('loading')) {
          loading = loading || state[key].loading;
        }
      }
      return loading;
    },
  },
});

export default store;