import {onDomReady} from "../../components/dynamic/observer";
import {Autoplay, FreeMode, Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    document.querySelectorAll('[data-producers-slider-container]')
        .forEach((el) => {
            const slideTranslateValueKey = Symbol('slideTranslateValue');
            const slider = new Swiper(el.querySelector('[data-producers-slider-slider]'), {
                modules: [Navigation, Autoplay, FreeMode],
                spaceBetween: 0,
                speed: 700,
                freeMode: true,
                autoplay: {
                    // delay: 5000,
                    delay: 1000,
                },
                navigation: {
                    prevEl: el.querySelector('[data-producers-slider-left]'),
                    nextEl: el.querySelector('[data-producers-slider-right]'),
                },
                breakpoints: {
                    0: {
                        slidesPerView: "auto",
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1200: {
                        slidesPerView: 4,
                    },
                },
            });
        })
}

onDomReady(init);