<template>
  <div class="cart-order-block cart-order-block_comment">
    <div class="cart-order-block__head">
      <h3 class="cart-order-block__title text__main">
        Комментарий к заказу
      </h3>
      <label for="order_comment" class="cart-order-block__label text text__small">
        На что стоит обратить внимание?
      </label>
    </div>


    <div class="cart-order-block__content form-field">
      <textarea
          name="comment"
          id="order_comment"
          cols="30"
          rows="10"
          class="cart-order-block__comment text text__small"
          placeholder="Комментарий"
          v-model="comment"
      >
      </textarea>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TheCartOrderComment",
  computed: {
    comment: {
      get() {
        return this.$store.state.order.comment
      },
      set (value) {
        this.$store.commit('order/setComment', value)
      }
    },
  }
}
</script>

<style lang="scss">

</style>