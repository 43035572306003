<template>
    <a href="/favorites" title="Перейти в избранное" class="header__cart-wrap">
      <div class="header__cart">
          <span class="header__cart-icon">
            <FavoritesIcon/>
              <span class="header__cart-count" v-if="count > 0">
                 {{ count }}
              </span>
          </span>
      </div>
    </a>
</template>

<script>
    import FavoritesIcon from "../icons/FavoritesIcon";
    import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
    export default {
        name: "TheFavoritesWidget",
        components: {
            FavoritesIcon
        },
        methods: {
            ...mapActions({
                'loadCheckout': 'favorites/loadCheckout'
            })
        },
        computed: {
            ...mapGetters('favorites', {
                'count': 'count'
            })
        },
        mounted() {
            this.loadCheckout();
        }
    }
</script>

<style lang="scss">

</style>