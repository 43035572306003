import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';
import RSlider from "../../components/rslider/rslider";

function init() {
    const articleSliderContainer = document.querySelectorAll('[data-article-slider-container]');
    if (!articleSliderContainer) {
        return;
    }

    articleSliderContainer.forEach((container) => {
        const articleSliderElement = container.querySelector('[data-article-slider]');
        if (!articleSliderElement) {
            return;
        }

        const leftArrow = container.querySelector('[data-article-slider-left]');
        const rightArrow = container.querySelector('[data-article-slider-right]');

        const slider = new Swiper(articleSliderElement, {
            modules: [Navigation],
            slidesPerView: "auto",
            speed: 500,
            spaceBetween: 0,
            preventInteractionOnTransition: true,
            navigation: {
                prevEl: leftArrow,
                nextEl: rightArrow,
            },
        });

        const textElement = container.querySelector('[data-rslider="article-text-slider"]');
        if (!textElement) {
            console.log('Could not find any element with advantages-text-slider');
            return;
        }
        const textSlider = new RSlider(
            textElement,
            {
                onStart: ({ slideIn, slideFriendsIn }) => {
                    slideFriendsIn.forEach((friendIn) => {
                        friendIn.classList.add('_active');
                    })
                    slideIn.classList.add('_active');
                },
                timeline: [
                    RSlider.action({
                        // К слайдам докидывается:
                        // К появляющемуся: --rslider-first-act-in-progress: 0.2;
                        // К исчезающему: --rslider-first-act-out-progress: 0.2;
                        // easing ниже настраивается
                        name: 'next-act',
                        duration: 500,
                        delay: 0,
                        // easeInOutCubic прогресс для "приходящего" слайда
                        // easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
                        // linear прогресс для "уходящего" слайда
                        // easingOut: (x) => x,
                        onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideIn.classList.add('_active');
                            slideOut.classList.remove('_active');

                            slideIn.classList.add('_in-next-act');
                            slideOut.classList.add('_out-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.add('_active');
                                friendIn.classList.add('_in-next-act');
                            })

                            setTimeout(() => {
                                slideIn.classList.remove('_in-next-act');

                                slideFriendsIn.forEach((friendIn) => {
                                    friendIn.classList.remove('_in-next-act');
                                })
                            }, 500);

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_active');
                                friendOut.classList.add('_out-next-act');
                            })
                        },
                        onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideOut.classList.remove('_out-next-act');

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_out-next-act');
                            })
                        }
                    }),
                ],
            });

        slider.on('slideNextTransitionStart', () => {
            textSlider.next();
        });

        slider.on('slidePrevTransitionStart', () => {
            textSlider.prev();
        });
    })
}

onDomReady(init);