<template>
  <div class="range-field filter-openable"
       :class="blockClasses"
       data-accordion-element
       data-is-opened="true"
  >
    <a href="javascript:void(0);"
       title="{{ field.label }}"
       class="range-field__link filter-openable__link"
       :class="elementClasses('link')"
       data-accordion-link
    >
      <span class="range-field__link-title filter-openable__link-title"
            v-if="field.label"
            :class="elementClasses('label')"
      >
        {{ field.label }}
      </span>
      <span class="range-field__help filter-openable__help"
            :class="elementClasses('link-help')"
            v-if="field.help"
      >
        <span class="range-field__help-icon filter-openable__help-icon">
          <QuestionBackground/>
        </span>
        <span class="range-field__help-description filter-openable__help-description">
          {{ field.help }}
        </span>
      </span>
      <span class="range-field__link-icon filter-openable__link-icon"
            :class="elementClasses('link-icon')"
      >
<!--        <ArrowDown/>-->
      </span>
    </a>

    <div class="range-field__content filter-openable__content"
         :class="elementClasses('content')"
         v-if="valueItem.value"
         data-accordion-block
    >
      <div class="range-field__content-inner">
        <div class="range-field__values"
             :class="elementClasses('values')"
        >
          <input type="text" v-model="valueItem.value.from">
          <div class="range-field__delimiter"></div>
          <input type="text" v-model="valueItem.value.to">
        </div>

        <slider
            class="range-field__slider"
            :model-value="valueModel"
            :min="field.data.min"
            :max="field.data.max"
            :step="field.data.step"
            @input="onSliderInput"
            ref="slider"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { fieldMixin } from "./fieldMixin";
  import Slider from '@vueform/slider'
  import ArrowDown from "../../components/icons/ArrowDown.vue";
  import QuestionBackground from "../../components/icons/QuestionBackground";

  export default {
    name: "RangeOpenableField",
    mixins: [
      fieldMixin
    ],
    components: {
      ArrowDown,
      QuestionBackground,
      Slider
    },
    computed: {
      valueModel() {
        if (!this.valueItem.value) {
          return [0, 0];
        }

        return [
          this.valueItem.value.from,
          this.valueItem.value.to
        ]
      }
    },
    methods: {
      checkValue(isInitialize) {
        if (!this.valueItem.value || isInitialize) {
          this.valueItem.value = {
            from: this.field.data.min,
            to: this.field.data.max
          };
        }
      },
      onSliderInput(value) {
        this.$emit('change', {target: this.$refs.slider.$el});
        if (value[0] && value[1]) {
          this.valueItem.value.from = value[0];
          this.valueItem.value.to = value[1];
          this.valueItem.value.isChanged = true
        }
      },
    },
    mounted() {
      this.checkValue(true);
    },

    beforeUpdate() {
      // this.checkValue();
    },

    watch: {
      field: {
        handler(newVal, oldVal){
          if (newVal.data.current) {
            this.valueItem.value = {
              from: newVal.data.current.min,
              to: newVal.data.current.max
            }
          }
        },
        deep: true
      }
    }
  }
</script>

<style scoped>

</style>