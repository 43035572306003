<template>
  <div class="custom-sort-field"
       :class="blockClasses"
  >
    <div class="custom-sort-field__container" :class="containerClasses" data-vue-custom-select>
      <a href="javascript:void(0);"
         class="custom-sort-field__link"
         :class="elementClasses('link')"
         @click="openToggle"
      >
        <span class="custom-sort-field__sort-icon" :class="elementClasses('icon')">
            <SortIcon/>
        </span>
<!--        <span class="custom-sort-field__choice-name">-->
<!--          Сначала&nbsp;-->
<!--        </span>-->
        <span class="custom-sort-field__value-wrap">
          <span class="custom-sort-field__choice-name custom-sort-field__choice-name_value">
            {{ currentValueAsLabel }}
          </span>
          <span class="custom-sort-field__icon" :class="elementClasses('icon')">
            <ArrowDown/>
          </span>
        </span>
      </a>

      <div class="custom-sort-field__values-wrapper" :class="elementClasses('values-wrapper')">
        <ul class="custom-sort-field__values" :class="elementClasses('values')">
          <li v-for="(choice, index) in field.choices"
              class="custom-sort-field__value"
              :class="elementClasses('value')"
          >
            <input
                class="input"
                type="radio"
                :name="choice.key"
                :value="choice.key"
                :id="id(choice, index)"
                v-model="valueItem.value"
            >
            <label :for="id(choice, index)"
                   class="custom-sort-field__item-label text__small"
                   :class="elementClasses('item-label')"
                   @click="openToggle"
            >
              {{ choice.label }}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { fieldMixin } from "./fieldMixin";
  import ArrowDown from "../../components/icons/ArrowDown.vue";
  import SortIcon from "../../components/icons/SortIcon.vue";

  export default {
    name: "SelectField",
    components: {ArrowDown, SortIcon},
    mixins: [
      fieldMixin
    ],
    data() {
      return {
        isOpen: false,
      }
    },
    computed: {
      containerClasses() {
        const classes = this.elementClasses('container');
        if (this.isOpen) {
          classes.push('_open');
        } else if (classes.indexOf('_open') > 0) {
          classes.splice(classes.indexOf('_open'), 1);
        }
        return classes;
      },
      currentValueAsLabel() {
        const currentChoice = this.field.choices.find((choice) => choice.key === this.valueItem.value);
        return currentChoice.label;
      }
    },
    methods: {
      checkValue() {
        if (!this.valueItem.value && this.field.choices.length) {
          this.valueItem.value = this.field.choices[0].key;
        }
      },
      id(choice, index) {
        index = index | 0;
        return `${this.field.key}_${choice.key}_${index}`;
      },
      openToggle() {
        this.isOpen = !this.isOpen;
      },
    },
    mounted() {
      this.checkValue();

      const el = this.$el.querySelector('[data-vue-custom-select]');
      document.addEventListener('click', (e) => {
        if (this.isOpen && !(el === e.target || el.contains(e.target))) {
          this.isOpen = false;
        }
      });
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>