<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <rect x="1" y="5" width="20" height="2" fill="#D7262D"/>
    <rect x="1" y="10" width="14" height="2" fill="#D7262D"/>
    <rect x="1" y="15" width="8" height="2" fill="#D7262D"/>
  </svg>
</template>

<script>
  export default {
    name: "ArrowDown"
  }
</script>

<style scoped>

</style>