import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import {singleBind} from "../../components/dynamic/bind";

function toggleToFavorites(e) {
    const button = this;
    if (!button.dataset.selected) {
        button.classList.add('to-favorite_selected');

        document.dispatchEvent(new CustomEvent('favoritesAdd', {
            detail: {
                chosen: button.dataset.chosen,
                id: parseInt(button.dataset.id),
            }
        }));
        window.goalManager.proceedYandexGoal("click_izbranoe");
    } else {
        button.classList.remove('to-favorite_selected');

        let removingItem = document.querySelector(`[data-selected="${button.dataset.selected}"]`);
        if (removingItem.closest('.favorites-page__item')) {
            removingItem.closest('.favorites-page__item').remove();
        }

        document.dispatchEvent(new CustomEvent('favoritesRemove', {
            detail: {
                key: button.dataset.selected
            }
        }));
    }
}

onDomChanges(() => {
    document.querySelectorAll('[data-favorites-toggle]').forEach((link) => {
        singleBind(link, 'initialized', 'click', toggleToFavorites);
    });
});