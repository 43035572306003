import {onDomChanges, onDomReady} from '../../components/dynamic/observer';

class Header {
  constructor(selector) {
    this.header = selector;
    this.headerHeight = this.header.offsetHeight;
    this.dropMenu = selector.querySelector('[data-drop-menu]');
    this.headerTop = selector.querySelector('[data-top]');
    this.hamburger = selector.querySelector('[data-hamburger]');
    this.closer = selector.querySelector('[data-menu-closer]');

    this.isOpened = false;
    this.scrollTop = 0;

    this.eventListeners();
  }

  open() {
    this.isOpened = true;
    this.header.classList.add('_menu-opened');
    this.dropMenu.classList.add('_menu-opened');
    this.hamburger.classList.add('_menu-opened');
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    this.header.style.position = 'fixed';
  }

  close() {
    this.isOpened = false;
    this.header.classList.remove('_menu-opened');
    this.dropMenu.classList.remove('_menu-opened');
    this.hamburger.classList.remove('_menu-opened');
    document.body.style.overflow = '';
    document.body.style.height = '';
    this.header.style.position = '';
  }

  addSticky() {
    const instance = this;
    if (instance.scrollTop > instance.headerHeight) {
      this.header.classList.add('_sticky');
    }

    if (instance.scrollTop > 0) {
      this.header.classList.add('_sticky');
    }
  }

  handleHeader() {
    const instance = this;
    const topHeight = this.headerTop.offsetHeight;

    if (instance.scrollTop < instance.lastScrollTop) {
      // up
      if (instance.scrollTop > instance.headerHeight) {
        // скролтоп ниже высоты шапки
        instance.header.classList.add('_up');
        instance.header.style.transform = `translate3d(0, -${topHeight}px, 0)`;
      }

      if (instance.scrollTop <= instance.headerHeight) {
        // скролтоп в зоне высоты шапки
        if (!instance.header.classList.contains('_up')) {
          instance.header.style.transform = `translate3d(0, -${instance.scrollTop}px, 0)`;
        }
      }

      if (instance.scrollTop <= 0) {
        instance.header.classList.remove('_up');
      }

    } else {
      // down
      instance.header.classList.remove('_up');

      if (instance.scrollTop <= instance.headerHeight) {
        // скролтоп в зоне высоты шапки
        instance.header.style.transform = `translate3d(0, -${instance.scrollTop}px, 0)`;
        instance.header.style.transition = '';
      } else {
        // скролтоп ниже высоты шапки
        instance.header.style.transform = `translate3d(0, -${this.headerHeight}px, 0)`;
        instance.header.style.transition = 'transform .3s linear';
      }
    }
  }

  eventListeners() {
    this.hamburger.addEventListener('click', () => {
      this.isOpened ? this.close() : this.open();
    });

    this.closer.addEventListener('click', () => {
      this.close();
    })

    window.addEventListener('scroll', () => {
      const instance = this;
      instance.scrollTop = window.scrollY;
      // instance.updateFooterPosition();

      instance.addSticky();

      instance.handleHeader();
      // instance.translateHeaderByFooter();

      instance.lastScrollTop = instance.scrollTop;
    });
  }
}

onDomReady(() => {
  const headerSelector = document.querySelector('[data-header]');
  if (headerSelector) {
    new Header(headerSelector);
  }
});

onDomChanges(() => {
  const headerSelector = document.querySelector('[data-header]');
  if (headerSelector) {
    new Header(headerSelector);
  }
});