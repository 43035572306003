<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="#D7262D" fill-opacity="0.1"/>
    <circle cx="30" cy="30" r="22" fill="#D7262D" fill-opacity="0.6"/>
    <path d="M18.625 20.25H20.5208L21.3333 23.5M21.3333 23.5L24.0417 34.3333H38.125L40.8333 23.5H21.3333Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.0416 39.75C24.9391 39.75 25.6666 39.0225 25.6666 38.125C25.6666 37.2275 24.9391 36.5 24.0416 36.5C23.1442 36.5 22.4166 37.2275 22.4166 38.125C22.4166 39.0225 23.1442 39.75 24.0416 39.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M38.125 39.75C39.0225 39.75 39.75 39.0225 39.75 38.125C39.75 37.2275 39.0225 36.5 38.125 36.5C37.2275 36.5 36.5 37.2275 36.5 38.125C36.5 39.0225 37.2275 39.75 38.125 39.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.9166 28.9167H33.25M31.0833 31.0833V26.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "EmptyCartIcon"
}
</script>
