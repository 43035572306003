<template>
  <div class="cart-order-block cart-order-block_contacts">
    <div class="cart-order-block__tabs tabs">
      <ul class="smart-tabs tabs__list cart-order-block__tabs">
        <li class="smart-tab tabs__item tabs__item_white" :class="{'_active': isPhysical}">
          <a href="javascript:void(0);" class="tabs__link" title="Физ. лицо"  @click="setPhysical">
            <span class="tabs__text">
              Физическое лицо
            </span>
          </a>
        </li>
        <li class="smart-tab tabs__item tabs__item_white" :class="{'_active': isLegal}">
          <a href="javascript:void(0);" class="tabs__link" title="Юр. лицо" @click="setLegal">
            <span class="tabs__text">
              Юридическое лицо
            </span>
          </a>
        </li>
      </ul>
    </div>


    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number text__main">
          1
        </div>
      </div>
      <h3 class="cart-order-block__title text__subtitle">
        Контактная информация
      </h3>
    </div>


    <div class="cart-order-block__content form-field">
      <ul class="cart-order-block__fields">
        <li class="cart-order-block__field">
          <label for="order_name" class="cart-order-block__label">
            Ваше имя
            <span class="text_red">
              *
            </span>
          </label>
          <input type="text" id="order_name" class="cart-order-block__input" v-model="name">
          <ItemErrors :errors="errors.name"/>
        </li>
        <li class="cart-order-block__field">
          <label for="order_phone" class="cart-order-block__label">
            Телефон
            <span class="text_red">
              *
            </span>
          </label>

          <input
              type="text"
              v-model="phone"
              id="order_phone"
              name="phone"
              required="required"
              class="cart-contacts__input required"
              ref="phone"
          >

          <ItemErrors :errors="errors.phone"/>
        </li>
        <li class="cart-order-block__field">
          <label for="order_email" class="cart-order-block__label">
            E-mail
          </label>
          <input type="text" id="order_email" class="cart-order-block__input" v-model="email">
          <ItemErrors :errors="errors.email"/>
        </li>
        <li class="cart-order-block__field" v-if="isLegal">
          <label for="order_organization" class="cart-order-block__label">
            Название организации
            <span class="text_red">
              *
            </span>
          </label>
          <input type="text" id="order_organization" class="cart-order-block__input" v-model="organization" required="required">
          <ItemErrors :errors="errors.organization"/>
        </li>
        <li class="cart-order-block__field" v-if="isLegal">
          <label for="order_inn" class="cart-order-block__label">
            ИНН
            <span class="text_red">
              *
            </span>
          </label>
          <input type="text" id="order_inn" class="cart-order-block__input" v-model="inn" required="required">
          <ItemErrors :errors="errors.inn"/>
        </li>
      </ul>

      <div class="cart-order-block__info text text__small">
        * Поля со звездочкой обязательны
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import ItemErrors from "./ItemErrors";
import {makeMask} from "../../../js/field/phone";

export default {
  name: "TheCartOrderContacts",
  components: {
    ItemErrors
  },
  mounted() {
    const phoneInput = this.$refs.phone;
    makeMask(phoneInput);
  },
  computed: {
    clientType: {
      get () {
        return this.$store.state.order.clientType
      },
      set (value) {
        this.$store.commit('order/setClientType', value);
        if (value === 10) {
          this.$store.commit('order/setPayment', 10)
        } else {
          this.$store.commit('order/setPayment', 40)
        }
      }
    },
    name: {
      get () {
        return this.$store.state.order.name
      },
      set (value) {
        this.$store.commit('order/setName', value)
      }
    },
    phone: {
      get () {
        return this.$store.state.order.phone
      },
      set (value) {
        this.$store.commit('order/setPhone', value)
      }
    },
    email: {
      get () {
        return this.$store.state.order.email
      },
      set (value) {
        this.$store.commit('order/setEmail', value)
      }
    },
    organization: {
      get () {
        return this.$store.state.order.organization
      },
      set (value) {
        this.$store.commit('order/setOrganization', value)
      }
    },
    inn: {
      get () {
        return this.$store.state.order.inn
      },
      set (value) {
        this.$store.commit('order/setInn', value)
      }
    },
    errors: {
      get () {
        return this.$store.state.order.errors
      },
    },
    ...mapGetters('order', {
      'isPhysical': 'isPhysical',
      'isLegal': 'isLegal',
    }),
    // ...mapState('profile', {
    //   'profileInfo': 'info'
    // })
  },
  methods: {
    setPhysical() {
      this.clientType = 10;
      document.dispatchEvent(new Event('setPhysical'));
    },
    setLegal() {
      this.clientType = 20;
    }
  },
  watch: {
    profileInfo: function (info) {
      this.name = info.name;
      this.email = info.email;
      this.phone = info.phone;
    }
  }
}
</script>

<style lang="scss">

</style>