require('./scroll-slider');
require('./swipe-slider');
require('./rslider');
require('./product-page-top');
require('./product-slider');
require('./article-slider')
require('./advantage-slider');
require('./partners-slider');
require('./producer-slider');
require('./hero-slider');
require('./produce-slider')