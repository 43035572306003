<template>
  <div class="checkbox-list-openable filter-openable"
       :class="blockClasses"
       data-accordion-element
       data-is-opened="true"
       v-if="field.choices.length"
  >
    <a href="javascript:void(0);"
       class="checkbox-list-openable__link filter-openable__link"
       :class="linkClasses"
       data-accordion-link
    >
      <span v-if="field.label"
            class="checkbox-list-openable__link-title filter-openable__link-title"
            :class="elementClasses('link-title')"
      >
        {{ field.label }}
      </span>
      <span class="checkbox-list-openable__help filter-openable__help"
            :class="elementClasses('link-help')"
            v-if="field.help"
      >
        <span class="checkbox-list-openable__help-icon filter-openable__help-icon">
          <QuestionBackground/>
        </span>
        <span class="checkbox-list-openable__help-description filter-openable__help-description">
          {{ field.help }}
        </span>
      </span>
      <span class="checkbox-list-openable__link-icon filter-openable__link-icon"
            :class="elementClasses('link-icon')"
      >
<!--          <ArrowDown/>-->
      </span>
    </a>

    <div class="checkbox-list-openable__content filter-openable__content"
         :class="contentClasses"
         v-if="valueItem.value"
         data-accordion-block
    >
      <div class="checkbox-list-openable__element"
           :class="elementClasses('element')"
           data-accordion-element
           ref="accordionElement"
      >
        <div class="checkbox-list-openable__block"
             :class="containerClasses"
             data-accordion-block
        >
          <div class="checkbox-list-openable__block-inner"
               :class="elementClasses('block-inner')"
          >
            <div class="checkbox-list-openable__search-wrap" ref="search" v-if="isSearchable">
              <div class="checkbox-list-openable__search">
                <input type="text"
                       class="checkbox-list-openable__search-input"
                       placeholder="Найти"
                       v-model="searchValue">
                <div class="checkbox-list-openable__search-icon">
                  <Search/>
                </div>
              </div>
            </div>

            <div class="checkbox-list-openable__values" :class="elementClasses('values')">
              <div class="checkbox-list-openable__search-no-result" v-if="!choices.length">
                Ничего не найдено
              </div>
              <div :class="elementClasses('value')"
                   v-for="(choice, index) in choices"
                   v-if="choices.length"
                   ref="filterFieldValue"
              >
                <input
                    type="radio"
                    :id="id(choice, index)"
                    :value="choice.key"
                    class="checkbox-list-openable__input"
                    :class="elementClasses('input')"
                    v-model="valueItem.value"
                >
                <label :for="id(choice, index)"
                       class="checkbox-list-openable__item-label"
                       :class="elementClasses('item-label')"
                >
                  <span class="checkbox-list-openable__choice-icon"
                        :class="elementClasses('choice-icon')"
                  >
                    <CheckMarkBlack/>
                  </span>
                  <span class="checkbox-list-openable__choice-name"
                        :class="elementClasses('choice-name')"
                  >
                    {{ choice.label }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <a href="javascript:void(0);"
           title="Показать все"
           class="checkbox-list-openable__show-all"
           :class="showAllLinkClasses"
           data-accordion-link
        >
          <span class="checkbox-list-openable__show-title" :class="elementClasses('show-title')">
            Еще
<!--            ({{ choices.length }})-->
          </span>
          <span class="checkbox-list-openable__hide-title" :class="elementClasses('hide-title')">
            Скрыть
          </span>
          <span class="checkbox-list-openable__show-all-icon"
                :class="elementClasses('show-all-icon')"
          ></span>
        </a>

      </div>
    </div>
  </div>
</template>

<script>
  import { fieldMixin } from "./fieldMixin";
  import ArrowDown from "../../components/icons/ArrowDown";
  import CheckMarkBlack from "../../components/icons/CheckMarkBlack";
  import QuestionBackground from "../../components/icons/QuestionBackground";
  import Search from "../../components/icons/Search";

  export default {
    name: "CheckboxListOpenable",
    components: {Search, QuestionBackground, CheckMarkBlack, ArrowDown},
    mixins: [
      fieldMixin
    ],
    data() {
      return {
        searchValue: '',
        isSearching: false,
        opened: false,
        sort: false
      }
    },
    computed: {
      choices() {
        let choices;
        if (!this.searchValue) {
          choices = this.field.choices;
        }
        choices = this.field.choices.filter((choice) => {
          const choiceKey = choice.key.toLowerCase();
          if (choiceKey.includes(this.searchValue.toLowerCase().trim())) {
            return choice;
          }
        });

        const orderedChoices = this.field.data.orderedChoices;
        if(!this.opened && !this.sort && (orderedChoices && orderedChoices.length)) {
          choices.sort((a, b) => {
            if (!orderedChoices.includes(a.key) || !orderedChoices.includes(b.key)) {
              return;
            }
            return orderedChoices.indexOf(a.key) - orderedChoices.indexOf(b.key);
          });
        }

        if (!this.opened && this.sort && choices.length > 4) {
          choices.sort((a, b) => this.valueItem.value.includes(b.key) - this.valueItem.value.includes(a.key));
        }

        return choices;
      },
      linkClasses() {
        const classes = this.elementClasses('link');
        if (this.searchInProgress) {
          classes.push('_disabled');
        }
        return classes;
      },
      contentClasses() {
        const classes = this.elementClasses('content');
        if (this.searchInProgress) {
          classes.push('_searchInProgress')
        }
        return classes;
      },
      containerClasses() {
        const classes = this.elementClasses('block');
        if (this.choices.length <= 4) {
          classes.push('_fit-content')
        }
        if (this.isSearchable) {
          classes.push('_searchable')
        }
        if (this.searchInProgress) {
          classes.push('_searchInProgress')
        }
        return classes;
      },
      showAllLinkClasses() {
        const classes = this.elementClasses('show-all');
        if (this.choices.length > 4 && !this.searchInProgress) {
          classes.push('_visible')
        }
        return classes;
      },
      isSearchable() {
        return this.field.data['searchable'] ? this.field.data['searchable'] : false;
      },
      searchInProgress() {
        return this.searchValue ? this.searchValue : false;
      },
    },
    methods: {
      checkValue() {
        if (!this.valueItem.value) {
          this.valueItem.value = [];
        }
      },
      id(choice, index) {
        index = index | 0;
        return `${this.field.key}_${choice.key}_${index}`;
      },
      change() {
        if (this.choices.length <= 4) {
          return;
        }
        let height;
        if (!this.valueItem.value.length) {
          height = 0;
          this.sort = false;
        } else {
          height = this.valueItem.value.length * this.$refs.filterFieldValue[0].offsetHeight;
          this.sort =true;

          if (this.isSearchable) {
            height += this.$refs.search.offsetHeight;
          }
        }

        if (this.$refs.accordionElement) {
          this.$refs.accordionElement.dispatchEvent(
            new CustomEvent('FilterInputChecked', {'detail': {'height': height}})
          );
        }
      },
    },
    mounted() {
      if (this.field.storeKey === 'pcd' || this.field.storeKey === 'dia') {
        if (this.choices.length === 1) {
          this.valueItem.value = [this.choices[0].key];
        }
      }
      this.checkValue();
      setTimeout(() => {
        this.$refs.accordionElement.addEventListener('ElementOpen', (e) => {
          this.opened = true;
        });
        this.$refs.accordionElement.addEventListener('ElementClose', (e) => {
          this.opened = false;
          document.dispatchEvent(new CustomEvent('AccordionElementClose'));
        });
      }, 2000);


      document.addEventListener('filter:after-submit', () => {
        this.change();
      });
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>