// Настройки фильтров под проект
import { FilterManagerPool } from "../../vue/filter/manager";
import { debounce } from "lodash";
import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import LiveEvent from "../../components/live/live";

const initFilterSubmits = () => {
  const filterSubmits = document.querySelectorAll('[data-filter-submit]:not([data-initialized="true"])');
  filterSubmits.forEach((filterSubmit) => {
    filterSubmit.dataset.initialized = "true";
    filterSubmit.addEventListener('click', () => {
      const manager = FilterManagerPool.getManager(filterSubmit.dataset.filterKey);
      manager.submit();
    });
  });
};

const initFilterClears = () => {
  const filterClears = document.querySelectorAll('[data-filter-clear]:not([data-initialized="true"])');
  filterClears.forEach((filterClear) => {
    filterClear.dataset.initialized = "true";
    filterClear.addEventListener('click', () => {
      const manager = FilterManagerPool.getManager(filterClear.dataset.filterKey);
      manager.submit(true);
    });
  });
};

onDomReady(() => {
  // Кнопка отправки
  initFilterSubmits();
  initFilterClears();

// Отправлять фильтр при любых изменениях
//   if (window.innerWidth <= 1199) {
//     document.addEventListener('filter:values-changed', debounce((e) => {
//       const { filterManager } = e.detail;
//       filterManager.submit();
//     }, 300));
//   }

// Кнопка мобильных фильтров
  new LiveEvent('click', '[data-filter-mobile-show]', function toggleLink(e) {
    e.preventDefault();
    let target = document.querySelector('[data-filter-mobile]');
    if (!target.classList.contains('_visible')) {
      target.classList.add('_visible');
      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';
    } else {
      target.classList.remove('_visible');
      document.body.style.height = '';
      document.body.style.overflow = '';
    }
  });


});

onDomChanges(() => {
  setTimeout(() => {
    initFilterSubmits();
  }, 100);
});
