<template>
  <div class="cart-order-block__pickup cart-order-delivery">
    <div class="cart-order-delivery__input-wrap">
      <label for="order_address" class="cart-order-delivery__label">
        Адрес доставки
      </label>
      <input type="text" class="cart-order-delivery__input" id="order_address" v-model="address">
      <ItemErrors :errors="errors.addressFilled"/>
    </div>

    <div class="cart-order-delivery__conditions">
      <ItemErrors :errors="errors.delivery"/>
      <ul class="cart-order-block__radio-list">
        <li class="cart-order-block__radio-item">
          <div class="cart-order-block__radio">
            <input type="radio" name="deliveryCompany" v-model.number="deliveryCompany" id="deliveryCompany_dellin" value="10">
            <label for="deliveryCompany_dellin" class="cart-order-block__input-label cart-order-block__input-label_flex">
              <span class="cart-order-block__text-wrap">
                  <span class="cart-order-block__label-title text__main">
                    Деловые линии
                  </span>
                  <span class="cart-order-block__label-description text text__small">
                     dellin.ru
                  </span>
              </span>
              <span class="cart-order-block__image-wrap">
                <img src="/static/images/base/dellin.png"
                     alt="Деловые линии"
                     class="cart-order-block__img"
                >
              </span>
            </label>
          </div>
        </li>

        <li class="cart-order-block__radio-item">
          <div class="cart-order-block__radio">
            <input type="radio" name="deliveryCompany" v-model.number="deliveryCompany" id="deliveryCompany_pek" value="20">
            <label for="deliveryCompany_pek" class="cart-order-block__input-label cart-order-block__input-label_flex">
              <span class="cart-order-block__text-wrap">
                  <span class="cart-order-block__label-title text__main">
                   ПЭК
                  </span>
                  <span class="cart-order-block__label-description text text__small">
                    pecom.ru
                  </span>
              </span>
              <span class="cart-order-block__image-wrap">
                <img src="/static/images/base/pek.png"
                     alt="ПЭК"
                     class="cart-order-block__img"
                >
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ItemErrors from "./ItemErrors.vue";
import {mapState} from "vuex";

export default {
  name: "TheCartOrderReceivingDelivery",
  components: {
    ItemErrors
  },
  computed: {
    address: {
      get () {
        return this.$store.state.order.address
      },
      set (value) {
        this.$store.commit('order/setAddress', value)
      }
    },
    deliveryCompany: {
      get () {
        return this.$store.state.order.deliveryCompany
      },
      set (value) {
        this.$store.commit('order/setDeliveryCompany',  parseInt(value, 10))
      }
    },
    errors: {
      get () {
        return this.$store.state.order.errors
      },
    },
    // ...mapState('profile', {
    //   'profileInfo': 'info'
    // })
  },
  // mounted() {
  //   this.address = this.profileInfo.address;
  // }
}
</script>
