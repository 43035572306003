import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    if (window.innerWidth > 1199) {
        return;
    }

    const containerElement = document.querySelector('[data-partners-slider-container]');
    if (!containerElement) {
        return;
    }

    const swipeSliderElement = containerElement.querySelector('[data-partners-slider]');
    if (!swipeSliderElement) {
        return;
    }

    const leftArrow = containerElement.querySelector('[data-partners-slider-left]');
    const rightArrow = containerElement.querySelector('[data-partners-slider-right]');

    const slider = new Swiper(swipeSliderElement, {
        modules: [Navigation],
        slidesPerView: "auto",
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        breakpoints: {
            0: {
                spaceBetween: 10,
            },
            768: {
                spaceBetween: 18,
            }
        },
    });
}

onDomReady(init);