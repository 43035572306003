<template>
  <div class="cart-order-block cart-order-block_receiving">
    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number text__main">
          3
        </div>
      </div>
      <h3 class="cart-order-block__title text__subtitle">
        Способ получения
      </h3>
    </div>

    <div class="cart-order-block__content form-field">

      <div class="cart-order-block__tabs tabs">
        <ul class="smart-tabs tabs__list">
          <li class="tabs__item smart-tab tabs__item tabs__item_round" :class="{'_active': isPickup}">
            <a href="javascript:void(0);" class="tabs__link" title="Самовывоз"  @click="setPickup">
              <span class="tabs__text text__card-title">Самовывоз</span>
            </a>
          </li>
          <li class="tabs__item smart-tab tabs__item tabs__item_round" :class="{'_active': isDelivery}">
            <a href="javascript:void(0);" class="tabs__link" title="Доставка" @click="setDelivery">
              <span class="tabs__text text__card-title">Доставка</span>
            </a>
          </li>
        </ul>
      </div>

      <div class="cart-order-block__tab-contents">
        <div class="cart-order-block__tab-content" v-if="isPickup">
          <TheCartOrderReceivingPickup/>
        </div>
        <div class="cart-order-block__tab-content" v-if="isDelivery">
          <TheCartOrderReceivingDelivery/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import TheCartOrderReceivingPickup from "./TheCartOrderReceivingPickup.vue";
import TheCartOrderReceivingDelivery from "./TheCartOrderReceivingDelivery.vue";

export default {
  name: "TheCartOrderReceiving",
  components: {TheCartOrderReceivingDelivery, TheCartOrderReceivingPickup},
  computed: {
    receiving: {
      get () {
        return this.$store.state.order.receiving
      },
      set (value) {
        this.$store.commit('order/setReceiving', value)
      }
    },
    ...mapGetters('order', {
      'isPickup': 'isPickup',
      'isDelivery': 'isDelivery',
    }),
  },
  methods: {
    setPickup() {
      this.receiving = 10;
    },
    setDelivery() {
      this.receiving = 20;
    }
  }
}
</script>

<style lang="scss">

</style>