<template>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 9L0 4L10 4L5 9Z" fill="#D7262D"/>
  </svg>
</template>

<script>
  export default {
    name: "ArrowDown"
  }
</script>

<style scoped>

</style>