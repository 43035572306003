<template>
  <div class="top-fieldset" :class="blockClasses">
    <div class="top-fieldset__fields" :class="elementClasses('fields')">
      <div :class="[...elementClasses('field'), ...elementClasses('field_' + field.key)]" v-for="field in fields">
        <Field v-if="field.visible" :fieldKey="field.key" :filterKey="filterKey"/>
      </div>

      <div class="top-fieldset__buttons-wrap top-fieldset__buttons-wrap_mobile">
        <button class="top-fieldset__button top-fieldset__button_mobile button button__default button_red"
                :class="elementClasses('button')"
                data-filter-submit
                :data-filter-key="filterKey"
                :disabled="isDisabled"
        >
          <span class="button__text text__button">
              Подобрать
          </span>
        </button>
      </div>
    </div>

    <div class="top-fieldset__buttons-wrap" :class="elementClasses('buttons-wrap')">
      <button class="top-fieldset__button button button__default button_red"
              :class="elementClasses('button')"
              data-filter-submit
              :data-filter-key="filterKey"
              :disabled="isDisabled"
      >
        <span class="button__text text__button">
              Подобрать
        </span>
      </button>

<!--      <a href="/request/recall-selection-modal"-->
<!--         title="Помощь с записью"-->
<!--         class="top-fieldset__question-link button button_question"-->
<!--         :class="elementClasses('question-link')"-->
<!--         data-modal-->
<!--         data-goal-yandex-click="click_order_a_call"-->
<!--         data-goal-yandex-submit="success_of_the_call_order"-->
<!--         data-goal-google-click="zakaz_zvonka#cliсk"-->
<!--         data-goal-google-submit="zakaz_zvonka#success"-->
<!--         data-goal-vk-click="click_order_a_call"-->
<!--         data-goal-vk-submit="success_of_the_call_order"-->
<!--      >-->
<!--        <span class="top-fieldset__question-icon button__icon"-->
<!--              :class="elementClasses('question-icon')"-->
<!--        >-->
<!--          <Question/>-->
<!--        </span>-->
<!--        <span class="top-fieldset__question-title button__title text__small"-->
<!--              :class="elementClasses('question-title')"-->
<!--        >-->
<!--          Помощь в подборе-->
<!--        </span>-->
<!--      </a>-->
    </div>
  </div>
</template>

<script>
  import {fieldsetMixin} from "./fieldsetMixin";
  import Field from "../field/Field.vue";
  import Question from "../../components/icons/Question";
  import { FilterManagerPool } from "../../../vue/filter/manager";

  export default {
    name: "TopFieldset",
    components: {Question, Field},
    mixins: [
      fieldsetMixin
    ],
    computed: {
      isDisabled() {
        if (this.fields) {
          return this.fields.some((field) => Boolean(this.values[field.key]) === false);
        }
        return false;
      },
      manufacturer() {
        return this.values ? this.values.manufacturer : null;
      },
      model() {
        return this.values ? this.values.model : null;
      },
      generation() {
        return this.values ? this.values.generation : null;
      },
      modification() {
        return this.values ? this.values.modification : null;
      },
    },
    watch: {
      manufacturer: {
        handler(value) {
          if (value !== undefined && value !== 0) {
            this.manager.filterStore.isLoading.model = true;

            if (this.model !== 0) {
              this.manager.filterStore.isLoading.generation = true;
              this.manager.filterStore.isLoading.modification = true;

              this.filtersValues[this.filterKey].model.value = 0;
              if (this.generation !== 0) {
                this.filtersValues[this.filterKey].generation.value = 0;
              }
              if (this.modification !== 0) {
                this.filtersValues[this.filterKey].modification.value = 0;
              }
            }

            this.manager.submit().then(() => {
              this.manager.filterStore.isLoading.model = false;
              this.manager.filterStore.isLoading.generation = false;
              this.manager.filterStore.isLoading.modification = false;
            });
          }

        }
      },
      model: {
        handler(value) {
          if (value !== undefined && value !== 0) {
            this.manager.filterStore.isLoading.generation = true;

            if (this.generation !== 0) {
              this.manager.filterStore.isLoading.modification = true;

              this.filtersValues[this.filterKey].generation.value = 0;
              if (this.modification !== 0) {
                this.filtersValues[this.filterKey].modification.value = 0;
              }
            }

            this.manager.submit().then(() => {
              this.manager.filterStore.isLoading.generation = false;
              this.manager.filterStore.isLoading.modification = false;
            });
          }
        }
      },
      generation: {
        handler(value) {
          if (value !== undefined && value !== 0) {
            this.manager.filterStore.isLoading.modification = true;

            if (this.modification !== 0) {
              this.filtersValues[this.filterKey].modification.value = 0;
            }

            this.manager.submit().then(() => {
              this.manager.filterStore.isLoading.modification = false;
            });
          }
        }
      },
    },
    mounted() {
      this.manager = FilterManagerPool.getManager(this.filterKey);
    }
  }
</script>

<style lang="scss">

</style>