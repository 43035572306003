<template>
  <div class="cart-page__vue">
    <div class="cart-page__content" v-if="count">
      <div class="cart-page__products">
        <TheCartPositions/>
      </div>

      <div class="cart-page__result">
        <TheCartResult/>
      </div>

      <div class="cart-page__order">
        <TheCartOrderContacts/>
        <TheCartOrderPay/>
        <TheCartOrderReceiving/>
        <TheCartOrderComment/>

        <div class="cart-page__button-wrap">
          <div class="cart-page__politic text">
            Подтверждая заказ вы соглашаетесь с
            <a :href=politicUrl target="_blank" class="cart-page__politic-link">
              политикой конфиденциальности
            </a>
          </div>

          <button class="cart-page__button button button__default button_red" @click="sendOrder" ref="submit">
            <span class="button__text text__button">
                Оформить заказ
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="cart-page__empty" v-else>
      <div class="cart-page__empty-content">
        <div class="cart-page__empty-wrap">
          <div class="cart-page__empty-icon">
            <EmptyCartIcon/>
          </div>
          <div class="cart-page__empty-text">
            <h4 class="cart-page__empty-title">
              Ваша корзина пуста
            </h4>

            <div class="cart-page__empty-subtitle text text__main">
              Перейдите в каталог для выбора товаров
            </div>
          </div>
        </div>

        <a href="/catalog/tyres" class="cart-page__empty-button button button_icon button_red">
                    <span class="button__text text text__button">
                      В каталог
                    </span>

          <span class="button__icon-wrap button__icon-wrap_fill">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" fill="none" viewBox="0 0 20 10"><path fill="#fff" d="M1 4H0v2h1V4Zm0 2h16.333V4H1v2Z"></path><path fill="#fff" d="m20 5-7 4V1l7 4Z"></path></svg>                    </span>
        </a>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import TheCartPositions from "./TheCartPositions.vue";
import TheCartResult from "./TheCartResult.vue";
import TireTrack from "../icons/TireTrack.vue";
import TheCartOrderContacts from "./TheCartOrderContacts.vue";
import TheCartOrderReceiving from "./TheCartOrderReceiving.vue";
import TheCartOrderPay from "./TheCartOrderPay.vue";
import TheCartOrderComment from "./TheCartOrderComment.vue";
import CartIcon from "../icons/CartIcon.vue";
import EmptyCartIcon from "../icons/EmptyCartIcon.vue";

export default {
  name: "TheCartPage",
  components: {
    EmptyCartIcon,
    CartIcon,
    TheCartResult,
    TheCartPositions,
    TireTrack,
    TheCartOrderContacts,
    TheCartOrderReceiving,
    TheCartOrderPay,
    TheCartOrderComment,
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout',
      'politicUrl': 'politicUrl'
    }),
    ...mapState('order', {
      'name': 'name',
      'phone': 'phone',
      'clientType': 'clientType',
      'organization': 'organization',
      'errors': 'errors'
    }),
    ...mapGetters('cart', {
      'total': 'total',
      'count': 'count'
    })
  },
  methods: {
    ...mapActions({
      'loadCheckout': 'cart/loadCheckout',
      'loadPoliticUrl': 'cart/loadPoliticUrl',
      'send': 'order/send',
    }),
    async sendOrder() {
      if (!this.name
          || !this.phone
          || (this.clientType === 20 && !this.organization)
          || (this.receiving === 20 && !this.address)
      ) {
        const success = await this.send(false);
      } else {
        // this.$refs.submit.disabled = true;
        const success = await this.send(false);
      }
    }
  },

  mounted() {
    this.loadCheckout();
    this.loadPoliticUrl();
  }
}
</script>
